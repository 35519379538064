import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

// Assets
import headerImage from '@assets/services/e-commerce/e-commerce-header.jpg';

import BugFreeIcon from '@assets/services/e-commerce/bug-free-platform.svg';
import BuyerExperienceIcon from '@assets/services/e-commerce/buyer-experience.svg';
import CustomizeNeedIcon from '@assets/services/e-commerce/customize-need.svg';
import ModernUIUXIcon from '@assets/services/e-commerce/modern-ui-ux.svg';
import ScalableSolutionIcon from '@assets/services/e-commerce/scalable-solution.svg';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 30px;
`;

const ServiceIconElem = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: start;
  flex-flow: column;
  padding: 15px 20px;
  flex: 0 0 33.33%;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex: 0 0 100%;
  }
  svg {
    margin-bottom: 20px;
  }
`;

const BgWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const ListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-width: 500px;
  margin: 0 auto;
  &.inline {
    max-width: none;
    display: inline-flex;
    margin: 0;
    flex: 0 0 50%;
    padding: 0 20px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex: 0 0 100%;
    }
  }
`;

const ListItem = styled.div`
  font-size: 14px;
  margin: 5px;
  font-weight: 600;
  line-height: 2;
  margin-bottom: 40px;
  &::before {
    content: ' ';
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary.base};
  }
`;

const ItemTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

const SubHeading = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 2;
`;

const MigServices = styled.div`
  padding-top: 0;
  display: flex;
  height: fit-content;
  flex-flow: row wrap;
  align-items: start;
  justify-content: center;
`;

const BlockChainService = () => {
  return (
    <Layout>
      <Helmet title={'eCommerce App Development'} />
      <PageHero image={headerImage} />
      <Heading title="eCommerce App Development" noHeadingIcon={true}>
        <p>
          We will help your eCommerce venture get off the ground at full speed.
          Our experience and delivery of many extraordinary solutions give us an
          edge to make your business succeed. We build a beautiful storefront
          that has a powerful management system and is mobile optimized. We
          specialize in developing e-Commerce and m-Commerce applications with
          several platforms, payment gateways and mobile payment processing
          frameworks. Get a responsive, smooth, easy to use and secure
          end-to-end solution.
        </p>
      </Heading>
      <BgWrapper>
        <Container>
          <SectionHeading>Why us</SectionHeading>
          <IconWrapper>
            <ServiceIconElem>
              <BugFreeIcon />
              <ItemTitle>A robust & bug-free eCommerce platform</ItemTitle>
            </ServiceIconElem>
            <ServiceIconElem>
              <ScalableSolutionIcon />
              <ItemTitle>A Scalable Solution</ItemTitle>
            </ServiceIconElem>
            <ServiceIconElem>
              <ModernUIUXIcon />
              <ItemTitle>Modern UI/UX to captivate your visitors</ItemTitle>
            </ServiceIconElem>
            <ServiceIconElem>
              <BuyerExperienceIcon />
              <ItemTitle>An engaging experience for your buyers</ItemTitle>
            </ServiceIconElem>
            <ServiceIconElem>
              <CustomizeNeedIcon />
              <ItemTitle>Customize All You Need</ItemTitle>
            </ServiceIconElem>
          </IconWrapper>
        </Container>
      </BgWrapper>
      <Container>
        <SectionHeading>All bases covered</SectionHeading>
        <MigServices>
          <ListWrapper className="inline">
            <ListItem>
              Outstanding User Experience across all intended platforms and
              devices.
            </ListItem>
            <ListItem>
              Multiple payment options supporting multiple currencies.
            </ListItem>
            <ListItem>
              Security from PCI Compliance to SSL Certificates to customized
              security modules.
            </ListItem>
          </ListWrapper>
          <ListWrapper className="inline">
            <ListItem>Easily management content</ListItem>
            <ListItem>Warehouse and Inventory Management</ListItem>
            <ListItem>
              Plug and Play Integration with over 100+ courier services across
              the globe
            </ListItem>
          </ListWrapper>
        </MigServices>
      </Container>
      <Container>
        <SectionHeading>We customise everything</SectionHeading>
        <SubHeading>
          Our solutions are completely customizable. Need an eCommerce app from
          scratch? Got it. Need an eCommerce app based on a framework like
          Meganto, Shopify, Zend etc.? We got you. An already based eCommerce
          app but you want to shift the technology? Done. No matter what your
          problem is regarding eCommerce, our team of experts are here to listen
          to you and give you a piece of proper advice and a well-working app of
          your dreams.
        </SubHeading>
      </Container>
      <Container>
        <SectionHeading>How do we stand out</SectionHeading>
        <MigServices>
          <ListWrapper className="inline">
            <ListItem>
              Easy and Manageable Payments, Refunds, Returns and cancellations.
            </ListItem>
            <ListItem>Fraud Protection on COD Orders.</ListItem>
            <ListItem>
              MarketPlace Management - Verification, Registration, Offers,
              Payments, Inventory for Merchants
            </ListItem>
            <ListItem>Global and Local Tax Management.</ListItem>
          </ListWrapper>
          <ListWrapper className="inline">
            <ListItem>
              Coupons, Ad Campaigns, Discounts Management Analytics and
              Heatmaps.
            </ListItem>
            <ListItem>A/B testing and Festive, special landing pages.</ListItem>
            <ListItem>Flexible, scalable and upgradeable solution</ListItem>
          </ListWrapper>
        </MigServices>
      </Container>
    </Layout>
  );
};

export default BlockChainService;
